<template>
  <div class="quiz-content__inner self-test commitment-intro">
    <div class="self-test__layout">
      <div>
        <h3
          class="self-test__header"
          v-html="$t('b2b_self_test_reevaluations_complete_header')"
        />
        <div
          class="self-test__description"
          v-html="$t('b2b_self_test_reevaluations_complete_description')"
        />
      </div>
      <div class="commitment-intro__background" />
      <div class="interactive-button__layout">
        <BaseButton
          :disabled="interactiveButtonState === 'DISABLED'"
          :loading="interactiveButtonState === 'PROCESSING'"
          :text="$t('b2b_self_test_reevaluations_complete_cta')"
          data-q-a="submit"
          @click="next"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseButton from '@shared/components/BaseButton.vue'
import Tracker from '@shared/Tracker'

export default {
  name: 'SelfTestReevaluationsComplete',
  components: {
    BaseButton,
  },
  props: {
    handleNextEvent: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      interactiveButtonState: 'ACTIVE',
    }
  },
  computed: {
    ...mapGetters('assessment', ['selfTestReevaluationsAnswers']),
  },
  methods: {
    ...mapActions('assessment', ['submitSelfTestReevaluations']),
    async next() {
      this.interactiveButtonState = 'PROCESSING'

      try {
        await this.submitSelfTestReevaluations({
          data: this.selfTestReevaluationsAnswers,
          action: () => {},
        })

        Tracker.trackKaiaEvent(
          {
            event_name: 'cb.prgrs.submit_monthly_checkup',
            app_area: 'onboarding',
            action: 'submit',
            object_type: 'initial_assessment',
            source: 'client_browser',
            screen_name: 'onb_finish_congratulations',
          },
          {
            custom_payload: this.selfTestReevaluationsAnswers,
          },
        )
      } catch {
        /* empty */
      }

      this.handleNextEvent()
    },
  },
}
</script>

<style lang="scss">
@import '@msk-us/views/css/Assessment/SelfTest';

.commitment-intro {
  background-color: $light-blue;
  &__background {
    height: 200px;
    margin-top: 32px;
    margin-bottom: -1px;
    background: url('@shared/assets/img/commitment-intro-background_people.svg')
      center bottom no-repeat;
    background-size: contain;
  }

  .self-test__layout {
    background: url('@shared/assets/img/commitment-intro-background_fireworks.svg')
      center 86px no-repeat;
    background-size: 75%;
  }

  .self-test__header {
    @include kaia-typography-h5($font-weight-bold);
  }

  .self-test__description {
    color: $midnight;
  }

  .interactive-button__layout {
    margin: 0 auto;
  }
}
</style>
