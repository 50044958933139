<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
    :class="[
      'landing-sticky-header',
      showInlineHeader ? 'inline' : '',
      shouldDisplay ? 'sticky' : '',
    ]"
  >
    <div class="landing-sticky-header-layout">
      <div class="landing-sticky-header__logo" />
      <button
        v-if="ctaBtnTitle !== ''"
        type="button"
        class="landing-sticky-header-cta"
        @click="handleCTAButtonClick"
      >
        <span
          class="landing-sticky-header-cta__title-small"
          v-html="ctaBtnTitleSmall"
        />
        <span
          :class="getHeaderCTATitleCSSClasses"
          v-html="ctaBtnTitle"
        />
      </button>
    </div>
  </div>
  <!-- eslint-enable vue/no-v-html -->
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { useI18n } from 'vue-i18n'
import { debounce } from '@shared/utils'

const props = defineProps({
  ctaBtnTitle: {
    type: String,
    default: '',
  },
  handleCTAButtonClick: {
    type: Function,
    default: () => {},
  },
  showInlineHeader: {
    type: Boolean,
    default: false,
  },
  showStickyHeader: {
    type: Function,
    default: () => {},
  },
  isGerman: {
    type: Boolean,
    default: true,
  },
})

const { t } = useI18n()
const shouldDisplay = ref(false)
const debounceHandleScroll = ref(null)

const ctaBtnTitleSmall = computed(() => {
  return t('b2b_get_started_btn_text')
})

const getHeaderCTATitleCSSClasses = computed(() => {
  return [
    'landing-sticky-header-cta__title',
    props.isGerman ? 'landing-sticky-header-cta__title--de' : '',
  ]
})

const handleScroll = () => {
  shouldDisplay.value = props.showStickyHeader()
}

onMounted(() => {
  debounceHandleScroll.value = debounce(handleScroll, 100)
  window.addEventListener('scroll', debounceHandleScroll.value)

  shouldDisplay.value = props.showStickyHeader()
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', debounceHandleScroll.value)
  debounceHandleScroll.value = null
})
</script>

<style lang="scss">
.landing-sticky-header {
  display: none;
  z-index: 4;
  left: 0;
  right: 0;
  top: 0;
  background: $white;

  &.inline {
    display: initial;
  }

  &.sticky {
    display: initial;
    position: fixed;
    box-shadow: 0 2px 2px rgba(10, 28, 64, 0.1);
  }

  &-layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 65px;
    width: 100%;
    padding: 0 12px;
    box-sizing: border-box;
  }

  &__logo {
    width: 100px;
    height: 15px;
    background-image: url('@shared/assets/img/kaia_text_logo.svg');
    background-position: left center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &-cta {
    background-color: $midnight;
    border: none;
    padding: 10px 33px 11px;
    color: $white;
    cursor: pointer;
    border-radius: 40px;

    &:hover,
    &:focus {
      background-color: $midnight-highlight;
    }

    &:active,
    &:visited {
      background-color: $ocean;
    }

    &__title {
      display: none;

      @include kaia-typography-p2($font-weight-semi-bold);

      &--de {
        font-size: 16px;
      }
    }

    &__title-small {
      @include kaia-typography-p2($font-weight-semi-bold);
    }
  }
}

.landing-sticky-header {
  &-layout {
    @media (min-width: $size-small) {
      padding: 0 24px;
    }

    @media (min-width: $size-medium) {
      height: 77px;
      padding: 0 54px;
    }

    @media (min-width: $size-large) {
      padding: 0 84px;
    }

    @media (min-width: $size-large-x) {
      padding: 0 120px;
    }

    @media (min-width: $size-large-xx) {
      width: 1200px;
      padding: 0;
      margin: 0 auto;
    }
  }

  &__logo {
    @media (min-width: $size-medium) {
      width: 180px;
      height: 26px;
    }
  }

  &-cta {
    @media (min-width: $size-medium) {
      padding: 16px 24px 17px;
      width: 286px;

      &::after {
        content: 'w';
        display: inline-block;
        width: 24px;
        margin-left: 7px;
        background-image: url(@shared/assets/img/short_right_arrow.svg);
        background-position: left top;
        background-repeat: no-repeat;
        background-size: 26px 52px;
        color: transparent;

        @include kaia-typography-p2($font-weight-semi-bold);
      }
    }

    &__title {
      @media (min-width: $size-medium) {
        display: inline;
      }
    }

    &__title-small {
      @media (min-width: $size-medium) {
        display: none;
      }
    }
  }
}
</style>
