import AbstractModel from './utils/abstractModel'
import types from './utils/types'

export default class UserProfile extends AbstractModel {
  /**
   * @type {number}
   */
  id

  /**
   * @type {string}
   */
  firstName

  /**
   * @type {string}
   */
  lastName

  /**
   * @type {Date, null} // 2023-07-19T16:22:21.074Z
   */
  acceptedTrackingAt

  /**
   * @type {Date, null} // 2023-07-19T16:22:21.074Z
   */
  acceptedDataProtectionAt

  /**
   * @type {number, null}
   */
  corporateId

  /**
   * @type {string}
   */
  language

  /**
   * @type {number}
   */
  age

  /**
   * @type {string}
   */
  country

  /**
   * @type {string}
   */
  gender

  /**
   * @type {string}
   */
  timezone

  /**
   * @type {boolean}
   */
  hasDisabledEmails

  /**
   * @type {boolean}
   */
  hasDisabledNotifications

  /**
   * @type {boolean}
   */
  isInCorporateVerificationPhase

  /**
   * @type {string}
   */
  insuranceType

  /**
   * @type {string, undefined}
   */
  phoneNumber

  constructor(json) {
    super(json)
    this.id = json.id
    this.firstName = json.first_name
    this.lastName = json.last_name
    this.acceptedTrackingAt = this.parseDate(json.accepted_tracking_at)
    this.acceptedDataProtectionAt = this.parseDate(
      json.accepted_data_protection_at,
    )
    this.corporateId = json.corporate_id
    this.language = json.language
    this.age = json.age
    this.country = json.country
    this.gender = json.gender
    this.timezone = json.gender
    this.hasDisabledEmails = json.has_disabled_emails
    this.hasDisabledNotifications = json.has_disabled_notifications
    this.isInCorporateVerificationPhase =
      json.is_in_corporate_verification_phase
    this.insuranceType = json.insurance_type
    this.phoneNumber = json.phone_number

    this.validate()
  }

  validate() {
    this.assertType('id', [types.NUMBER])
    this.assertType('firstName', [types.STRING, types.NULL])
    this.assertType('lastName', [types.STRING, types.NULL])
    this.assertType('acceptedTrackingAt', [types.DATE, types.NULL])
    this.assertType('acceptedDataProtectionAt', [types.DATE, types.NULL])
    this.assertType('corporateId', [types.NUMBER, types.NULL])
    this.assertType('language', [types.STRING])
    this.assertType('age', [types.NUMBER, types.NULL])
    this.assertType('country', [types.STRING])
    this.assertType('gender', [types.STRING])
    this.assertType('timezone', [types.STRING])
    this.assertType('hasDisabledEmails', [types.BOOLEAN])
    this.assertType('hasDisabledNotifications', [types.BOOLEAN])
    this.assertType('isInCorporateVerificationPhase', [types.BOOLEAN])
    this.assertType('insuranceType', [types.STRING])
    this.assertType('phoneNumber', [types.STRING, types.NULL])
  }

  /**
   * Identifies whether user gave tracking consent
   *
   * @readonly
   * @type {boolean}
   */
  get hasAcceptedTracking() {
    return this?.acceptedTrackingAt !== null
  }

  /**
   * Identifies whether user accepted DPA
   * @readonly
   * @type {boolean}
   */
  get hasAcceptedDataProtection() {
    return this?.acceptedDataProtectionAt !== null
  }
}
