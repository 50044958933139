<template>
  <BaseModal
    ref="modal"
    :title="$t('b2b__support_modal_generic_title')"
  >
    <template #trigger="{ open }">
      <BaseButton
        variant="outlined"
        data-qa="get-help-modal-trigger"
        size="small"
        @click="open"
      >
        {{ $t(buttonTextKey) }}
      </BaseButton>
    </template>
    <BaseBanner
      v-if="apiError"
      variant="warning"
      :title="$t('b2b__support_modal_generic__error_banner__title')"
      :description="$t('b2b__support_modal_generic__error_banner__description')"
    />
    <template #footer>
      <form class="get-help__form">
        <p class="kds-typography-title-medium">
          {{ $t('b2b__support_modal_generic_how_can_we_help') }}
        </p>
        <TextareaField
          v-bind="fieldAttrs.issue"
          :placeholder="$t('b2b__support_modal_generic_placeholder_issue')"
        />
        <p class="kds-typography-title-medium">
          {{ $t('b2b__support_modal_generic_information_to_help_support') }}
        </p>
        <TextField
          v-bind="fieldAttrs.firstName"
          autocomplete="given-name"
          data-qa="help-logging-in-first-name"
          :label="$t('b2b__support_modal_generic_label_first_name')"
        />
        <TextField
          v-bind="fieldAttrs.lastName"
          autocomplete="family-name"
          data-qa="help-logging-in-last-name"
          :label="$t('b2b__support_modal_generic_label_last_name')"
        />
        <TextField
          v-bind="fieldAttrs.employer"
          data-qa="help-logging-in-employer"
          :label="$t('b2b__support_modal__not_eligible__form_label_employer')"
        />
        <p class="kds-typography-title-medium">
          {{
            $t('b2b__support_modal__not_eligible__form_contact_section_title')
          }}
        </p>
        <TextField
          v-bind="fieldAttrs.email"
          autocomplete="email"
          data-qa="help-logging-in-email"
          type="email"
          :label="$t('b2b__support_modal_generic_label_email')"
        />
        <BaseButton
          class="help-logging-in__submit"
          data-qa="help-logging-in_submit"
          :success="success"
          :loading="loading"
          @click="submit"
        >
          {{ $t('b2b__support_modal_generic_submit_button') }}
        </BaseButton>
      </form>
    </template>
  </BaseModal>
  <BaseModal
    ref="supportTicketCreationSuccessModal"
    class="success-submit-support-modal"
    @close="emit('submit-success')"
  >
    <template #header>
      <CheckIcon class="modal-mask__container--header--check-icon" />
    </template>
    <p
      class="kds-typography-paragraph-large modal-mask__container--body--message"
    >
      {{ $t('b2b__support_modal_generic__success_modal_title') }}
    </p>
  </BaseModal>
</template>

<script setup>
import { ref, computed } from 'vue'

import { submitSupportTicket } from '@shared/api-endpoints'
import { isFirstNameKey, isLastNameKey } from '@shared/utils'
import { getEligibilitySessionHash } from '@msk-us/state/b2b'

import useForm from '@shared/composables/useForm.js'

import BaseBanner from '@shared/components/BaseBanner.vue'
import BaseButton from '@shared/components/BaseButton.vue'
import BaseModal from '@shared/components/BaseModal.vue'
import TextField from '@shared/components/form/TextField.vue'
import TextareaField from '@shared/components/form/TextareaField.vue'
import CheckIcon from '@shared/assets/icon/CheckIcon.vue'

const modal = ref(null)
const supportTicketCreationSuccessModal = ref(null)

const apiError = ref(false)

const emit = defineEmits(['submit-success'])
const props = defineProps({
  additionalData: {
    type: Object,
    default: () => ({}),
  },
  corporate: {
    type: Object,
    default: () => ({}),
  },
  subject: {
    type: String,
    default: '',
  },
  buttonTextKey: {
    type: String,
    default: 'b2b__support_modal_generic_submit_button',
  },
})

const firstName = computed(() => {
  const verificationFieldKeys = Object.keys(props.additionalData)

  const firstNameKey = verificationFieldKeys.find(isFirstNameKey)
  return (firstNameKey && props.additionalData[firstNameKey]) || ''
})

const lastName = computed(() => {
  const verificationFieldKeys = Object.keys(props.additionalData)

  const lastNameKey = verificationFieldKeys.find(isLastNameKey)
  return (lastNameKey && props.additionalData[lastNameKey]) || ''
})

const form = ref({
  issue: '',
  firstName: firstName.value,
  lastName: lastName.value,
  employer: '',
  email: '',
})

const { fieldAttrs, submit, loading, success } = useForm(
  form,
  ref({
    issue: { validations: [] },
    firstName: { validations: ['required'] },
    lastName: { validations: [] },
    employer: { validations: [] },
    email: { validations: ['required', 'email'] },
  }),
  {
    onSubmit: async () => {
      apiError.value = false

      await submitSupportTicket({
        email: form.value.email,
        name:
          [form.value.firstName, form.value.lastName].join(' ') ||
          'Unknown name',
        first_name: form.value.firstName,
        last_name: form.value.lastName,
        subject: props.subject,
        text: form.value.issue,
        additional_data: {
          eligibility_session: getEligibilitySessionHash(),
          corporate: props.corporate.id,
          employer_entered_by_user: form.value.employer,
          ...props.additionalData,
        },
      })
    },
    onSuccess: () => {
      modal.value.close()
      supportTicketCreationSuccessModal.value.open()
    },
    onError: () => {
      apiError.value = true
    },
  },
)
</script>

<style lang="scss" scoped>
.get-help {
  @include padding(xs);

  &__additional_data {
    @include flex-container(column, flex-start, md);
    padding-left: 20px;
  }

  &__form {
    @include flex-container(column);
  }

  &__submit {
    width: 100%;
  }
}

.success-submit-support-modal .modal-mask {
  &__container {
    &--header {
      &--check-icon {
        margin: auto;
        height: 48px;
        width: 48px;
      }
    }

    &--body {
      &--message {
        text-align: center;
      }
    }
  }
}
</style>
