<template>
  <!-- eslint-disable vue/no-v-html -->
  <UnauthenticatedLayout>
    <div class="kds-content-block voucher">
      <p
        class="kds-typography-title-large"
        v-html="$t('b2b_voucher_welcome_text', { name: corporateName })"
      />
      <p
        class="kds-typography-title-medium"
        v-html="$t('b2b_voucher_instructions_intro')"
      />
      <div class="voucher__instructions">
        <div class="instructions--step">
          <h4
            class="kds-typography-title-medium"
            v-html="$t('b2b_voucher_instructions_step_1_title')"
          />
          <p
            class="kds-typography-paragraph-normal"
            v-html="$t('b2b_voucher_instructions_step_1_text')"
          />
        </div>
        <div class="instructions--step">
          <h4
            class="kds-typography-title-medium"
            v-html="$t('b2b_voucher_instructions_step_2_title')"
          />
          <p
            class="kds-typography-paragraph-normal"
            v-html="$t('b2b_voucher_instructions_step_2_text')"
          />
        </div>
        <div class="instructions--step">
          <h4
            class="kds-typography-title-medium"
            v-html="$t('b2b_voucher_instructions_step_3_title')"
          />
          <p
            class="kds-typography-paragraph-normal"
            v-html="$t('b2b_voucher_instructions_step_3_text')"
          />
        </div>
      </div>
      <div class="voucher__form">
        <TextField
          v-bind="fieldAttrs.voucherCode"
          :label="$t('b2b_voucher_placeholder')"
          data-qa="voucher-code"
        />
        <BaseButton
          :text="$t('b2b_voucher_submit')"
          data-qa="verify-voucher"
          :success="success"
          :loading="loading"
          @click="submit"
        />
      </div>
    </div>
  </UnauthenticatedLayout>
  <!-- eslint-enable vue/no-v-html -->
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import useForm from '@shared/composables/useForm.js'
import ROUTE from '@msk-us/router/names'

import TextField from '@shared/components/form/TextField.vue'
import BaseButton from '@shared/components/BaseButton.vue'
import UnauthenticatedLayout from '@msk-us/layouts/UnauthenticatedLayout.vue'

const { t } = useI18n()
const store = useStore()
const route = useRoute()
const router = useRouter()

const voucher = computed(() => store.getters['b2b/voucher'])
const formData = computed(() => store.getters['b2b/formData'])
const corporate = computed(() => store.getters['b2b/corporate'])
const verificationParams = computed(
  () => store.getters['b2b/verificationParams'],
)
const corporateName = computed(() => corporate.value.title || '')

const form = ref({
  voucherCode: formData.value.voucherCode || '',
})

const formConfig = computed(() => ({
  voucherCode: {
    validations: ['required'],
  },
}))

const { submit, loading, success, fieldAttrs, addErrors } = useForm(
  form,
  formConfig,
  {
    onSubmit: async () => {
      await store.dispatch('b2b/checkVoucher', form.value.voucherCode)
    },
    onSuccess: () => {
      router.push({
        name: ROUTE.B2B_SIGN_UP,
        params: {
          corp_name: voucher.value.corporate_name,
        },
      })
    },
    onError: (error) => {
      if (error.response && error.response.status === 403) {
        addErrors('voucherCode', t('generic_voucher_not_valid'))
      }
    },
  },
)

onMounted(() => {
  form.value.voucherCode =
    route.params?.voucherCode || verificationParams.value.voucher || ''

  if (form.value.voucherCode) {
    submit()
  }
})
</script>

<style lang="scss">
.voucher {
  &__instructions {
    max-width: 400px;
  }

  &__form {
    @include flex-container(column, flex-start, md);

    max-width: 400px;
  }
}
</style>
