<template>
  <main class="b2b-layout">
    <FullPagePreloader v-if="showPreloader"></FullPagePreloader>

    <StickyHeader
      :key="currentRoute"
      :show-sticky-header="stickyHeaderConfig.showStickyHeader"
      :sticky-header-caption="stickyHeaderConfig.ctaCaption"
      :cta-was-clicked="stickyHeaderConfig.ctaWasClicked"
    />

    <section class="first-page">
      <div class="left-wrapper">
        <component
          :is="currentMedia('left')"
          class="left-content"
        />
      </div>
      <div class="right-wrapper">
        <component
          :is="currentMedia('right')"
          class="top-content"
        />
        <router-view
          :show-preloader="showPreloader"
          :configure-sticky-header="configureStickyHeader"
          class="right-content"
        />
      </div>
    </section>
  </main>
</template>

<script>
import StickyHeader from '@shared/components/StickyHeader.vue'
import FullPagePreloader from '@shared/components/FullPagePreloader.vue'
import ROUTE from '@msk-us/router/names'
import { mapActions, mapGetters } from 'vuex'
import $disease from '@shared/VueDisease'
import { isMobile } from '@shared/utils'
import { isFromApp } from '@shared/helpers/isFromApp'
import B2BCorporateEligibilityVerificationMedia from '@shared/components/B2BCorporateEligibilityVerificationMedia.vue'
import InformationMedia from '@shared/components/Quiz/InformationMedia.vue'
import PartnerSearchMedia from '@shared/components/PartnerSearchMedia.vue'
import B2bMedia from '@shared/components/B2bMedia.vue'
import B2BTherapyOutlookMedia from '@shared/components/B2BTherapyOutlookMedia.vue'
import B2BSignUpMedia from '@shared/components/B2BSignUpMedia.vue'
import { useI18n } from 'vue-i18n'

export default {
  name: 'B2BLayout',
  components: {
    FullPagePreloader,
    StickyHeader,
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data() {
    return {
      showPreloader: false,
      showOnMobile: false,
      stickyHeaderConfig: {
        ctaWasClicked: () => {},
        showStickyHeader: () => false,
        ctaCaption: '',
      },
    }
  },
  computed: {
    ...mapGetters(['lang']),
    ...mapGetters('b2b', ['corporate']),
    currentRoute() {
      return this.$route.name
    },
  },
  watch: {
    $route() {
      // KAIA-8312
      document.documentElement.scrollTop = 0

      // reset sticky header before new view is mounted
      this.configureStickyHeader({
        ctaWasClicked: () => {},
        showStickyHeader: () => false,
        ctaCaption: '',
      })
    },
  },
  mounted() {
    this.utmsToStore(window.location.search)
  },
  methods: {
    ...mapActions('b2b', ['utmsToStore']),
    configureStickyHeader(config) {
      this.stickyHeaderConfig.ctaWasClicked = config.ctaWasClicked
      this.stickyHeaderConfig.showStickyHeader = config.showStickyHeader
      this.stickyHeaderConfig.ctaCaption = this.t(config.ctaCaption)
    },
    currentMedia(mediaType) {
      // Do not show a difficult exercise media image for COPD patients
      if ($disease.is_copd) {
        return B2BCorporateEligibilityVerificationMedia
      }

      switch (this.currentRoute) {
        case ROUTE.B2B_PARTNERS:
          return PartnerSearchMedia

        case ROUTE.B2B_DOWNLOAD_LINK:
          return B2BTherapyOutlookMedia

        case ROUTE.B2B_CORPORATE_ELIGIBILITY_VERIFICATION:
        case ROUTE.B2B_CORPORATE_ELIGIBILITY_LIST_VERIFICATION:
        case ROUTE.B2B_PARTICIPATION_ID_VERIFICATION:
        case ROUTE.B2B_SOLERA_VERIFICATION:
        case ROUTE.B2B_UHCHUB_VERIFICATION:
        case ROUTE.B2B_SIGN_UP:
          return B2BSignUpMedia

        case ROUTE.ASSESSMENT_COMPLETION:
          if (!isMobile() && !isFromApp()) {
            return B2bMedia
          }
          break

        default:
          return InformationMedia
      }
    },
  },
}
</script>

<style lang="scss">
.b2b-layout {
  width: 100%;
  min-width: 320px;

  .first-page {
    display: flex;
    flex-direction: row;
    min-height: 100vh;

    .left-wrapper,
    .right-wrapper {
      width: 100%;

      &.left-wrapper {
        @media (max-width: $breakpoint-tablet) {
          display: none;
        }

        @media screen and (-ms-high-contrast: active),
          (-ms-high-contrast: none) {
          position: relative;
        }
      }

      .left-content,
      .top-content,
      .right-content {
        box-sizing: border-box;
        left: 0;
        min-height: 100vh;
        position: sticky;
        top: 0;
        z-index: 1;

        &.no-overflow {
          overflow: visible;
        }

        &.top-content {
          display: none;
          min-height: unset;
          position: unset;

          &.show-on-mobile {
            @media (max-width: $breakpoint-tablet) {
              display: block;
            }
          }
        }

        &.left-content {
          @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
            position: absolute;
            width: 100%;
            bottom: 0;
          }
        }

        &.right-content {
          @media (max-width: $breakpoint-tablet) {
            min-height: 100%;
          }

          &.no-min-height {
            @media (max-width: $breakpoint-tablet) {
              min-height: 0;
            }
          }
        }

        .content {
          margin: 0 auto;
          max-width: 498px;
          width: 100%;
          box-sizing: border-box;

          @media (max-width: $breakpoint-mobile) {
            margin: 0;
            max-width: unset;
            padding-left: 24px;
            padding-right: 24px;
          }
        }

        &.welcome-theme,
        &.quiz-content__inner {
          display: flex;
          flex-direction: column;

          &.welcome-theme {
            @media (max-width: $breakpoint-tablet) {
              background-color: $sky;
            }
          }

          .content-header {
            padding-top: 32px;
            margin-bottom: 32px;
            flex-direction: row;
          }

          .welcome-text {
            margin-top: 36px;
            margin-bottom: 36px;
            text-align: center;
            color: $gray-50;
          }

          h1 {
            font-size: 17px;
            line-height: 26px;
            margin: 0 auto;
            text-align: center;
          }

          h2 {
            text-align: center;
            font-size: 24px;
            line-height: 28px;

            &.page-title {
              font-size: 40px;
              line-height: 60px;
              margin-top: 0;
              margin-bottom: 30px;
              font-weight: $font-weight-bold;
              word-wrap: break-word;

              @media (min-width: $breakpoint-mobile) {
                font-size: 48px;
              }

              @media (min-width: $breakpoint-tablet) {
                font-size: 44px;
              }

              @media (width: $breakpoint-tablet) {
                font-size: 48px;
              }
            }
          }

          h3 {
            &.progress-title {
              text-align: center;
              font-size: 21px;
              line-height: 27px;
              font-weight: $font-weight-bold;
              margin-bottom: 5px;
            }
          }

          .quiz-content-container {
            flex-grow: 1;
            justify-content: center;
          }

          .figure {
            text-align: center;
            margin-top: 64px;
            display: none;

            img {
              width: 80%;
              max-width: 420px;
            }

            @media (max-width: $breakpoint-tablet) {
              display: block;
            }
          }
        }

        &.kinesiophobia-welcome-theme {
          h2 {
            &.page-title {
              font-size: 22px;

              @media (min-width: $breakpoint-mobile) {
                font-size: 36px;
              }

              @media (min-width: $breakpoint-tablet) {
                font-size: 26px;
              }

              @media (width: $breakpoint-tablet) {
                font-size: 36px;
              }
            }
          }
        }

        &.quiz-results-theme {
          .content-header {
            padding-top: 32px;
            margin-bottom: 32px;
          }

          h1 {
            font-size: 17px;
            line-height: 26px;
            margin: 0 auto;
            text-align: center;
          }

          h2 {
            font-size: 17px;
            line-height: 26px;
            margin: 0;
            text-align: center;

            span {
              text-decoration: underline;
            }
          }

          .results-container-inner {
            .quiz-content-container__inner {
              max-width: 368px;
            }
          }

          h3 {
            text-align: center;
            font-size: 34px;
            margin: 0 0 20px;
            font-weight: bold;
            word-wrap: break-word;

            @media (min-width: $breakpoint-mobile) {
              font-size: 50px;
            }

            @media (min-width: $breakpoint-tablet) {
              font-size: 38px;
            }

            @media (width: $breakpoint-tablet) {
              font-size: 50px;
            }

            @media (max-width: $breakpoint-tablet) {
              margin-bottom: 10px;
            }
          }

          .recommendations-list {
            text-align: left;

            p {
              font-size: 17px;
              line-height: 26px;
              padding: 0;
              margin: 0;
            }

            h5 {
              font-size: 17px;
              line-height: 26px;
              font-weight: bold;
              margin: 30px 0 0;
            }
          }
        }

        &.kinesiophobia-results {
          h3 {
            font-size: 22px;

            @media (min-width: $breakpoint-mobile) {
              font-size: 32px;
            }

            @media (min-width: $breakpoint-tablet) {
              font-size: 26px;
            }

            @media (width: $breakpoint-tablet) {
              font-size: 32px;
            }
          }
        }
      }
    }

    @media (max-width: $breakpoint-mobile) {
      min-height: 100%;
    }
  }
}
</style>
