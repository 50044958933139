<template>
  <ResponsiveLayout>
    <div class="kds-main-wrapper">
      <img
        src="@msk-us/assets/img/unitedhealthcare.svg"
        alt="UnitedHealthcare Logo"
        class="corporate-logo"
      />
      <div class="kds-content-block">
        <p class="kds-typography-display-small">
          {{ $t('uhc_vpt_employer_search__title') }}
        </p>
        <p class="kds-typography-paragraph-large kds-color-text-secondary">
          {{ $t('uhc_vpt_employer_search__description') }}
        </p>
      </div>
      <BaseBanner
        variant="info"
        class="banner"
      >
        <div class="kds-main-wrapper banner__wrapper">
          <div class="kds-content-block">
            <p class="kds-typography-title-xlarge">
              {{ $t('uhc_vpt_employer_search__employer__title') }}
            </p>
            <p class="kds-typography-paragraph-large kds-color-text-secondary">
              {{ $t('uhc_vpt_employer_search__employer__description') }}
            </p>
          </div>
          <PartnerSearchCtrl
            class="banner__employer-search"
            :pending-results="pendingResults"
            :corporate-key="corporateKey"
            :loading="loadingSearchResults"
            :error="employerSearchErrorKey && $t(employerSearchErrorKey)"
            input-field-text-key="uhc_vpt_employer_search__employer_search__placeholder"
            @search="handleSearch"
            @ctrl-state-change="handlePartnerSearchCtrlState"
          />
        </div>
      </BaseBanner>
      <BaseBanner
        variant="success"
        class="banner"
      >
        <div class="kds-main-wrapper banner__wrapper">
          <div class="kds-content-block">
            <p class="kds-typography-title-xlarge">
              {{ $t('uhc_vpt_employer_search__self_payer__title') }}
            </p>
            <p class="kds-typography-paragraph-large kds-color-text-secondary">
              {{ $t('uhc_vpt_employer_search__self_payer__description') }}
            </p>
          </div>
          <BaseButton
            class="banner__cta"
            @click="goToVptPrimer"
          >
            {{ $t('uhc_vpt_employer_search__self_payer__cta') }}
          </BaseButton>
        </div>
      </BaseBanner>
    </div>
  </ResponsiveLayout>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { isFromApp } from '@shared/helpers/isFromApp.js'
import { debounce, getCookie } from '@shared/utils.js'
import { searchCorporate } from '@shared/api-endpoints'
import VueCookieConsent from '@shared/VueCookieConsent.js'
import ROUTE from '@msk-us/router/names.js'
import groupVerificationConfig from '@msk-us/config/b2b-group-verification.json'

import PartnerSearchCtrl from '@msk-us/components/PartnerSearch/PartnerSearchCtrl.vue'
import ResponsiveLayout from '@shared/components/ResponsiveLayout.vue'
import BaseBanner from '@shared/components/BaseBanner.vue'
import BaseButton from '@shared/components/BaseButton.vue'

const store = useStore()
const router = useRouter()
const route = useRoute()

const loadingSearchResults = ref(false)
const searchResults = ref([])
const pendingResults = ref(Promise.resolve('null'))
const corporateKey = ref(route.params?.corp_name || '')
const lang = computed(() => store.getters.lang)
const employerSearchErrorKey = ref('')

const debouncedSearchCorporate = debounce((search, token, callback) => {
  callback(searchCorporate(search, '', token))
}, 250)

let searchRequestAbortController
const handleSearch = (searchTerm) => {
  if (!searchTerm || searchTerm.length <= 2) {
    return
  }

  loadingSearchResults.value = false
  searchResults.value = []

  if (searchRequestAbortController) {
    searchRequestAbortController.abort(
      'Cancel corporate search as newer search request is fired',
    )
  }

  searchRequestAbortController = new AbortController()

  loadingSearchResults.value = true

  debouncedSearchCorporate(
    searchTerm.trim(),
    searchRequestAbortController.signal,
    (corporatesPromise) => {
      pendingResults.value = corporatesPromise.then((result) => {
        // filter out groups (as a approximation to only show employers)
        result.data.corporates = result.data.corporates.filter(
          (corporate) =>
            !Object.keys(groupVerificationConfig).includes(corporate.key),
        )
        return result
      })
      loadingSearchResults.value = false
    },
  )
}

const goToInformation = (corporateKey) => {
  router.push({
    name: ROUTE.B2B_INFORMATION,
    params: {
      corp_name: corporateKey,
    },
  })
}

const goToVptPrimer = () => {
  router.push({
    name: ROUTE.UHC_VPT_PRIMER,
  })
}

const handlePartnerSearchCtrlState = ({ state, value }) => {
  employerSearchErrorKey.value = ''
  if (state === 'disabled') {
    if (value !== null && typeof value === 'object') {
      // corporate that is not a customer selected
      employerSearchErrorKey.value =
        'uhc_vpt_employer_search__error__not_a_customer'
    } else if (value === '') {
      // nothing entered
      employerSearchErrorKey.value =
        'uhc_vpt_employer_search__error__nothing_entered'
    } else {
      // something entered but no valid corporate selected
      employerSearchErrorKey.value =
        'uhc_vpt_employer_search__error__not_a_customer'
    }
  }
  if (state === 'validated') {
    goToInformation(value?.key)
  }
}

const consentCookie = getCookie('CookieConsent')
if (consentCookie === null) {
  VueCookieConsent(window, document, consentCookie, '', isFromApp(), lang.value)
}
</script>

<style lang="scss" scoped>
.corporate-logo {
  height: 49px;
  align-self: start;
}

.banner {
  margin: 0;

  &__wrapper {
    align-items: center;
    text-align: center;
  }

  &__employer-search {
    width: 100%;
    margin: 0;
  }

  &__cta {
    width: 100%;
  }
}
</style>
