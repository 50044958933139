<template>
  <!-- eslint-disable vue/no-v-html -->
  <UnauthenticatedLayout class="kds-main-wrapper">
    <BaseBanner
      :title="$t('generic_sign_up_eligible_sign_up_banner_title')"
      :description="$t('generic_sign_up_eligible_sign_up_banner_subtitle')"
      scroll-into-view
    />
    <div class="kds-content-block">
      <p
        class="kds-typography-display-small"
        v-html="$t('generic_sign_up_with_kaia')"
      />
      <p class="kds-typography-paragraph-large">
        {{ $t('generic_sign_up_personal_info_text') }}
      </p>
    </div>

    <BaseBanner
      v-if="showLoginBanner"
      variant="important"
      scroll-into-view
    >
      <p
        class="kds-typography-paragraph-medium"
        v-html="
          $t('b2b__verification__warning_description_with_support_email', {
            VUE_APP_SUPPORT_EMAIL: supportEmail,
          })
        "
      ></p>
      <template #actions>
        <BaseButton
          data-q-a="login"
          @click="router.push({ name: ROUTE.B2B_LOGIN })"
        >
          {{ $t('generic_login_with_existing_account') }}
        </BaseButton>
      </template>
    </BaseBanner>

    <BaseBanner
      v-if="errorBannerData"
      variant="warning"
      :title="$t(errorBannerData.title)"
      scroll-into-view
    >
      <div v-html="errorBannerData.text" />
    </BaseBanner>

    <div class="kds-content-block">
      <TextField
        v-if="showPersonalInformation"
        v-bind="fieldAttrs.firstName"
        autocomplete="given-name"
        :label="$t('b2b_sign_up_name_label')"
        data-qa="first-name"
      />

      <TextField
        v-if="showPersonalInformation"
        v-bind="fieldAttrs.lastName"
        autocomplete="family-name"
        :label="$t('b2b_sign_up_last_name_label')"
        data-qa="last-name"
      />

      <DateOfBirthField
        v-if="showPersonalInformation"
        v-bind="fieldAttrs.dateOfBirth"
      />

      <TextField
        v-bind="fieldAttrs.email"
        type="email"
        autocomplete="email"
        data-qa="email"
        :label="$t('generic_field_email')"
      />

      <PhonenumberField
        v-bind="fieldAttrs.phoneNumber"
        :label="$t('b2b_sign_up_phone_number_label')"
        :country="country"
      />

      <PasswordField
        v-bind="fieldAttrs.password"
        show-password-requirements
        data-qa="password"
      />

      <BaseBanner variant="info">
        <CheckboxField
          v-bind="fieldAttrs.acceptTerms"
          data-qa="accept-terms-and-conditions"
        >
          <DocumentViewer :link="$t('b2b__new_account__accept_terms')" />
        </CheckboxField>
      </BaseBanner>

      <BaseButton
        data-qa="submit"
        :loading="loading"
        :success="success"
        @click="submit"
      >
        {{ $t('generic_create_account') }}
      </BaseButton>
      <AlreadyHaveAccount />
    </div>
  </UnauthenticatedLayout>
  <!-- eslint-enable vue/no-v-html -->
</template>

<script setup>
import { ref, computed, watchEffect, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import {
  getTimezone,
  logW,
  logE,
  isDoBKey,
  isFirstNameKey,
  isLastNameKey,
  isEmailKey,
} from '@shared/utils'
import { B2B_AUTH_METHODS } from '@msk-us/router/b2b-hooks'
import ROUTE from '@msk-us/router/names'
import Tracker from '@shared/Tracker'

import useForm from '@shared/composables/useForm.js'

import UnauthenticatedLayout from '@msk-us/layouts/UnauthenticatedLayout.vue'

import AlreadyHaveAccount from '@msk-us/components/AlreadyHaveAccount.vue'
import BaseBanner from '@shared/components/BaseBanner.vue'
import BaseButton from '@shared/components/BaseButton.vue'
import CheckboxField from '@shared/components/form/CheckboxField.vue'
import DateOfBirthField from '@shared/components/form/DateOfBirthField.vue'
import PasswordField from '@shared/components/form/PasswordField.vue'
import PhonenumberField from '@shared/components/form/PhonenumberField.vue'
import TextField from '@shared/components/form/TextField.vue'

import DocumentViewer from '@shared/components/DocumentViewer.vue'

const { t } = useI18n()
const store = useStore()
const route = useRoute()
const router = useRouter()

const showPersonalInformation = ref(false)
const showLoginBanner = ref(false)
const errorBannerData = ref(null)

const lang = computed(() => store.getters.lang)
const country = computed(() => {
  return store.getters.country || import.meta.env.VITE_DEFAULT_COUNTRY_CODE
})

const formData = computed(() => store.getters['b2b/formData'])

const corporateEligibilityData = computed(
  () => store.getters['b2b/corporateEligibility'],
)

const supportEmail = computed(() => import.meta.env.VITE_SUPPORT_EMAIL)

const corporate = computed(() => store.getters['b2b/corporate'])

const kaiaSubmissionTrackEvent = (success, errorMessage) => {
  const utmData = store.getters['b2b/utmData']

  const custom_payload = {
    search_success: success,
    corporate_id: corporate.value.id,
    corporate_name: corporate.value.title,
    corporate_type: corporate.value.corporateTypes.toString(),
    ...(corporate.value.channel
      ? { corporate_channel: corporate.value.channel }
      : null),
    ...(errorMessage ? { error_message: errorMessage } : null),
    ...(utmData.medium ? { utm_medium: utmData.medium } : null),
    ...(utmData.source ? { utm_source: utmData.source } : null),
    ...(utmData.campaign ? { utm_campaign: utmData.campaign } : null),
    ...(utmData.content ? { utm_content: utmData.content } : null),
  }

  Tracker.trackKaiaEvent(
    {
      event_name: 'cb.onbrdg.create_account',
      app_area: 'onboarding',
      action: 'create',
      object_type: 'account',
      source: 'client_browser',
      screen_name: 'onb_account_creation',
    },
    {
      custom_payload,
    },
  )
}

// searches the corporateEligibilityData and verificationParams for an entry
// where the entries key matches the keyIdentifier condition and returns the
// entries value (null otherwise), used to prefill the form with known data.
const getFromKnownData = (keyIdentifier) =>
  [
    ...Object.entries(formData.value.corporateEligibilityData),
    ...Object.entries(store.getters['b2b/verificationParams']),
  ].find(([key]) => keyIdentifier(key))?.[1]

const form = ref({
  firstName: formData.value.firstName || getFromKnownData(isFirstNameKey) || '',
  lastName: formData.value.lastName || getFromKnownData(isLastNameKey) || '',
  dateOfBirth: formData.value.dateOfBirth || getFromKnownData(isDoBKey) || '',
  email: formData.value.email || getFromKnownData(isEmailKey) || '',
  phoneNumber: formData.value.phoneNumber || '',
  password: formData.value.password || '',
  acceptTerms: formData.value.acceptTerms || false,
})

watchEffect(() => {
  store.commit('b2b/setFormData', { ...form.value })
})

const formConfig = computed(() => {
  const config = {}

  if (showPersonalInformation.value) {
    config.firstName = { validations: ['required'] }
    config.lastName = { validations: ['required'] }
    config.dateOfBirth = { validations: ['dob'] }
  }

  config.email = { validations: ['required', 'email'] }
  config.phoneNumber = { validations: ['required', 'phone'] }
  config.password = { validations: ['password'] }
  config.acceptTerms = { validations: ['accepted'] }

  return config
})

const { fieldAttrs, loading, submit, success } = useForm(form, formConfig, {
  onSubmit: async () => {
    const emailCode = store.getters['b2b/voucher']?.emailCode

    const lookupKey =
      corporateEligibilityData.value?.lookupKey || route.query.lookupKey || ''

    const stayLoggedIn =
      import.meta.env.VITE_MODE === 'prod' || window.stayLoggedIn !== false

    // INFO: Uses the url 'auth/sign_up/' which is referenced in the onError block when determining where the error was thrown
    await store.dispatch('b2b/signUp', {
      ...form.value,
      phoneNumber: form.value.phoneNumber.split(' ').join(''),
      corporateId: corporate.value.id,
      language: lang.value,
      country: country.value,
      timezone: getTimezone(),
      verificationEmail: emailCode,
      landingPageExperimentVariant: localStorage.getItem(
        'landing_page_experiment_variant',
      ),
      lookupKey,
      // Directly true for US users, because they don't have to accept the terms separately
      acceptedDataProtection: true,
      acceptedTracking: true,
      stayLoggedIn,
    })

    const { code } = store.getters['b2b/voucher'] || {}
    const { token, eligible_token, type } =
      store.getters['b2b/verificationParams'] || {}

    if (token) {
      await store.dispatch('b2b/subscribeUserWithInsuranceToken', {
        corporateKey: corporate.value.key,
        token,
        type,
      })
    } else if (code) {
      await store.dispatch('b2b/subscribeUserWithVoucher', { code })
    } else if (eligible_token) {
      await store.dispatch('b2b/subscribeUserWithEligibleToken', {
        corporate_key: corporate.value.key,
        eligible_token,
      })
    }
  },
  onSuccess: async () => {
    kaiaSubmissionTrackEvent(true)

    router.push({
      name: ROUTE.B2B_DOWNLOAD_LINK,
    })
  },
  onError: (error) => {
    errorBannerData.value = null
    kaiaSubmissionTrackEvent(false, error)

    const status = error?.response?.status
    const url = error?.response?.config?.url

    switch (url) {
      case 'auth/sign_up/': {
        logW(
          'SIGN_UP_ERROR',
          [
            `corporate id: ${corporate.value.id}`,
            `corporate key: ${corporate.value.key}`,
            `http status: ${error?.response?.status}`,
            `http status text: ${error?.response?.statusText}`,
          ].join('|'),
        )

        switch (status) {
          case 403:
            showLoginBanner.value = true
            break
          default:
            errorBannerData.value = {
              title: t('generic__error_title'),
              text: t('generic__error_message', {
                email: supportEmail,
              }),
            }
        }

        break
      }
      default: {
        const { user } = store.getters

        logE(
          `creating subscription for user ${user?.id} failed with code ${error?.response?.status}`,
        )

        logW(
          'SIGN_UP_ERROR::SUBSCRIBE_USER_ERROR',
          [
            `corporate id: ${corporate.value.id}`,
            `corporate key: ${corporate.value.key}`,
            `user id: ${user?.value?.id}`,
            `http status: ${error?.response?.status}`,
            `http status text: ${error?.response?.statusText}`,
          ].join('|'),
        )

        switch (true) {
          case corporate.value.preferredSignupMethod ===
            B2B_AUTH_METHODS.UHCHUB:
            errorBannerData.value = {
              title: t('b2b__sponsor__label__activation_failed'),
              text: t('b2b__uhchub_verification__description'),
            }
            break
          default:
            errorBannerData.value = {
              title: t('b2b__sponsor__label__activation_failed'),
              text: t(
                'b2b__sponsor__label__kaia_pro_failed_to_activate_with_sponsor_huk_with_support_email',
                { VUE_APP_SUPPORT_EMAIL: supportEmail.value },
              ),
            }
        }
      }
    }
  },
})

onMounted(() => {
  Object.keys(formConfig.value).forEach((fieldKey) => {
    if (route.query[fieldKey]) {
      form.value[fieldKey] = route.query[fieldKey]
    }
  })

  showPersonalInformation.value =
    !form.value.firstName || !form.value.lastName || !form.value.dateOfBirth
})
</script>

<style lang="scss">
:root {
  --base-banner--margin: 0;
}

// TODO: Overwrite password requirements ordering as in the old layout the horizontal space is not enough
.password-input__password-requirements {
  @include grid-container(1fr 1fr, auto, xs);

  @include media-up(xl) {
    @include grid-container(repeat(4, 1fr), auto, md);
  }
}
</style>
