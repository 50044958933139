<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="unauthenticated-layout">
    <SimpleLandingLogo
      v-if="showLandingLogo"
      :corporate="corporate"
    />

    <slot />

    <SupportEmail />
    <LanguageSwitcher />
  </div>
  <!-- eslint-enable vue/no-v-html -->
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import SupportEmail from '@shared/components/SupportEmail.vue'
import SimpleLandingLogo from '@msk-us/components/SimpleLandingLogo.vue'
import LanguageSwitcher from '@shared/components/LanguageSwitcher.vue'

const props = defineProps({
  showLandingLogo: {
    type: Boolean,
    default: true,
  },
})

const store = useStore()

const corporate = computed(() => store.getters['b2b/corporate'] || null)
</script>

<style lang="scss">
.unauthenticated-layout {
  @include padding(md);
  @include flex-container(column, flex-start, xl);

  @include media-up(md) {
    @include padding(xxl);
  }
}
</style>
