<template>
  <div class="quiz-content__inner self-test past-consultation">
    <div class="self-test__layout">
      <div>
        <div>
          <h3
            class="self-test__header"
            v-html="headerText"
          />
          <div
            class="self-test__description"
            v-html="$t('b2b_self_test_past_consultation_description')"
          />
        </div>
        <fieldset class="form-container">
          <div
            v-for="item in items"
            :key="item.key"
            :class="[
              'quiz-checkbox',
              { 'quiz-checkbox--checked': isChecked(item) },
            ]"
            @click="handleCheckboxChange(item)"
          >
            <label class="quiz-checkbox__label kaia-p2-semi-bold">
              {{ $t(item.value) }}
            </label>
            <div class="quiz-checkbox__layout">
              <CustomCheckbox
                :value="item.key"
                :checked="isChecked(item)"
                :data-q-a="item.key"
              />
            </div>
          </div>
        </fieldset>
      </div>
      <div>
        <BaseButton
          :text="interactiveButtonText"
          :variant="interactiveButtonCSSClasses"
          data-q-a="submit"
          @click="handleNextEvent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomCheckbox from '@shared/components/CustomCheckbox.vue'
import BaseButton from '@shared/components/BaseButton.vue'

import painLocationDictionary from '@msk-us/config/pain-locations.json'
import pastConsultationsList from '@msk-us/config/past-consultations.json'
import { useI18n } from 'vue-i18n'

export default {
  name: 'SelfTestReevaluationsPastConsultations',
  components: {
    CustomCheckbox,
    BaseButton,
  },
  props: {
    handleNextEvent: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data() {
    return {
      items: pastConsultationsList.concat([]),
    }
  },
  computed: {
    ...mapGetters(['user', 'lang']),
    ...mapGetters('assessment', ['selectedPastConsultations']),
    interactiveButtonText() {
      return this.selectedPastConsultations.length > 0
        ? this.t('generic_action_continue')
        : this.t('b2b_self_test_past_consultation_none_of_these')
    },
    interactiveButtonCSSClasses() {
      if (this.selectedPastConsultations.length > 0) {
        return 'primary'
      }

      return 'outlined'
    },
    headerText() {
      const painLocationValue = this.user.painLocation || ''

      return this.t('b2b_self_test_reevaluations_past_consultation_header', {
        painLocation: this.painLocationLabel,
        painLocationGenderEnding: painLocationValue === 'hand' ? 'e' : '',
      })
    },
    painLocationLabel() {
      const painLocationLabel =
        (painLocationDictionary[this.user.painLocation] &&
          painLocationDictionary[this.user.painLocation].label) ||
        ''

      return this.lang.indexOf('en-') === 0
        ? this.t(painLocationLabel).toLowerCase()
        : this.t(painLocationLabel)
    },
  },
  methods: {
    handleCheckboxChange(item) {
      let items = this.selectedPastConsultations.concat([])
      if (items.includes(item.key)) {
        items = items.filter((i) => i !== item.key)
      } else {
        items.push(item.key)
      }
      this.$store.commit('assessment/updatePastConsultations', items)
    },
    isChecked(item) {
      return this.selectedPastConsultations.includes(item.key)
    },
  },
}
</script>

<style lang="scss">
@import '@msk-us/views/css/Assessment/SelfTest';

.past-consultation {
  .interactive-button__layout {
    margin: 32px auto 0;
  }

  .form-container {
    width: 100%;
    @media (max-width: $breakpoint-tablet) {
      flex: 0 0 50%;
    }
    ul {
      list-style: none;
      padding: 0;
      max-width: 420px;
      margin: 0 auto;
    }
    li {
      margin-bottom: 8px;
    }
  }
}
</style>
