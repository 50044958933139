<template>
  <!-- TODO refactor towards a generic SSO error page -->
  <div class="virginpulse-sso-error-content">
    <div class="content">
      <CorporateHeader />
      <div class="content-header">
        <p
          class="kaia-p3-bold"
          v-html="$t('b2b__virginpulse_signup_error__title')"
        />
      </div>
      <div
        class="instructions"
        v-html="errorMessage"
      />
      <button
        type="button"
        class="btn btn-primary"
        @click="redirectToStart"
        v-html="$t('generic__try_again')"
      />
      <div
        class="instructions"
        v-html="
          $t(
            'b2b__virginpulse_signup_error__contact_support_with_support_email',
            {
              VUE_APP_SUPPORT_EMAIL: supportEmail,
            },
          )
        "
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import env from '@shared/env'
import ROUTE from '@msk-us/router/names'
import CorporateHeader from '@shared/components/CorporateHeader.vue'
import { useI18n } from 'vue-i18n'

export default {
  name: 'B2BVirginpulseSSOError',
  components: {
    CorporateHeader,
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data() {
    return {
      env,
    }
  },
  computed: {
    ...mapGetters('b2b', ['corporate']),
    supportEmail() {
      return env.VITE_SUPPORT_EMAIL
    },
    errorMessage() {
      switch (this.$route.query?.code) {
        case 'UNAUTHORIZED':
          return this.t('b2b__virginpulse_signup_error__unauthorized')
        case 'UNKNOWN_SPONSOR_ID':
          return this.t('b2b__virginpulse_signup_error__unknown_sponsor_id')
        case 'NO_ACTIVE_SUBSCRIPTION':
          return this.t('b2b__virginpulse_signup_error__no_active_subscription')
        case 'DATA_ERROR':
        case 'SERVICE_ERROR':
        default:
          return this.t('b2b__virginpulse_signup_error__unknown_error')
      }
    },
  },
  methods: {
    redirectToStart() {
      this.$router.push({
        name: ROUTE.B2B_INFORMATION,
        params: {
          corp_name: this.corporate.key,
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import './css/B2BVirginpulseSSOError';
</style>
