<!--
TODO: Component to remove

Used in:
src/eu/app/components/InsuranceVerificationSection.vue|31 col 9| <custom-checkbox
src/eu/app/views/Assessment/QuestionnaireSelfTest/SelfTestActivities.vue|36 col 13| <custom-checkbox
src/eu/app/views/Login.vue|60 col 13| <custom-checkbox
src/eu/app/views/PrescriptionInsurance.vue|126 col 11| <custom-checkbox
src/us/msk/views/B2BVirginpulseSSOCompleted.vue|79 col 15| <custom-checkbox
src/us/msk/views/B2BVirginpulseSSOCompleted.vue|106 col 15| <custom-checkbox
src/shared/components/ConfirmationBox.vue|10 col 9| <custom-checkbox
-->

<template>
  <div :class="getCustomCheckboxCSSClasses">
    <input
      :id="name"
      :checked="checked"
      :value="value"
      :data-qa="dataQA"
      :data-qa-value="dataQAValue"
      type="checkbox"
      class="custom-checkbox__input js-custom-checkbox__input"
      @click="handleClickEvent"
      @change="handleChangeEvent"
      @focus="handleFocusEvent"
      @blur="handleBlurEvent"
    />
  </div>
</template>

<script>
const ENTER = 13

export default {
  name: 'CustomCheckbox',
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    dataQA: {
      type: String,
      default: '',
    },
    dataQAValue: {
      type: String,
      default: '',
    },
  },
  emits: ['change', 'focus', 'blur'],
  data() {
    return {
      focused: false,
    }
  },
  computed: {
    getCustomCheckboxCSSClasses() {
      const classes = ['custom-checkbox']

      if (this.checked) {
        classes.push('custom-checkbox--checked')
      }

      if (this.focused) {
        classes.push('custom-checkbox--focused')
      }

      return classes
    },
  },
  methods: {
    handleChangeEvent(event) {
      const { checked, value } = event.target
      this.$emit('change', {
        checked,
        value,
      })
    },
    handleFocusEvent() {
      this.focused = true
      this.$emit('focus')

      this.$el.addEventListener('keydown', this.handleKeyDownEvent)
    },
    handleBlurEvent() {
      this.focused = false
      this.$emit('blur')

      this.$el.removeEventListener('keydown', this.handleKeyDownEvent)
    },
    handleClickEvent(event) {
      event.target.focus()
    },
    handleKeyDownEvent(event) {
      if (event.keyCode === ENTER) {
        this.$el.querySelector('.custom-checkbox__input').click()
      }
    },
  },
}
</script>

<style lang="scss">
.custom-checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid $gray-15;
  border-radius: 3px;

  &__input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  &--checked {
    background-color: $secondary;
    border: 1px solid $secondary;
    background-image: url(@shared/assets/img/checkbox.svg);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 60%;
  }

  &--focused {
    border-color: $ocean;
  }
}
</style>
