import { createRouter, createWebHistory } from 'vue-router'
import Tracker from '@shared/Tracker'
import store from '@msk-us/state/store'
import { fromAppToStorage, isFromApp } from '@shared/helpers/isFromApp'
import VueCookieConsent from '@shared/VueCookieConsent'
import { getCookie } from '@shared/utils.js'
import env from '@shared/env.js'
import trackDetailedViewScreen from '@msk-us/helpers/trackDetailedViewScreen.js'
import routes from './routes'
import ROUTE from './names'
import { beforeEachB2B } from './b2b-hooks'
import beforeEachAssessment from './assessment-hooks'
import useAuthStore from '@shared/store/useAuthStore.js'

const initRouter = () => {
  const authStore = useAuthStore()
  const router = createRouter({
    routes,
    linkActiveClass: 'open active',
    scrollBehavior: (to, from, savedPosition) =>
      savedPosition || { x: 0, y: 0 },
    history: createWebHistory(env.VITE_PUBLIC_PATH),
  })

  router.beforeEach(async (routeTo, routeFrom, next) => {
    if (routeTo.query.from_app) {
      fromAppToStorage()

      const { client_app_id, client_version } = routeTo.query

      authStore.hostClientAppId = Array.isArray(client_app_id)
        ? client_app_id[0]
        : client_app_id
      authStore.hostClientVersion = Array.isArray(client_version)
        ? client_version[0]
        : client_version
    }

    VueCookieConsent(
      window,
      document,
      getCookie('CookieConsent'),
      store.getters.country,
      isFromApp(),
      store.getters.lang,
    )

    if (routeTo.path.startsWith('/b2b') || routeTo.query.corporate != null) {
      return beforeEachB2B(routeTo, routeFrom, next)
    }

    if (routeTo.path.startsWith('/assessment')) {
      return beforeEachAssessment(routeTo, routeFrom, next)
    }

    return next()
  })

  router.afterEach((routeTo) => {
    // better be sure and not disable/enable only on `recordSession` change but on all route changes
    if (routeTo?.meta?.recordSession) {
      Tracker.startSessionRecording()
    } else {
      Tracker.stopSessionRecording()
    }

    Tracker.trackPageView(routeTo.fullPath)

    if (routeTo.meta && routeTo.meta.name) {
      // detailedTrackViewScreen
      const detailedViewScreenRouteNames = [
        'onb_eligibility_verification',
        'onb_account_creation',
        'corporate_landing_page',
        'simplified_corporate_landing_page',
      ]
      if (detailedViewScreenRouteNames.includes(routeTo.meta.name)) {
        trackDetailedViewScreen(
          Tracker,
          routeTo.meta.name,
          store.getters['b2b/corporate'],
          store.getters['b2b/utmData'],
        )
      } else {
        Tracker.trackViewScreen(routeTo.meta.name)
      }
    }
  })

  // ensure that the first page_view that is ever tracked on a session contains the initial Href
  // (this is needed for proper utm tracking)
  Tracker.trackPageView(window.location.href)

  return router
}

export default initRouter

export { ROUTE }
