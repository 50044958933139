<template>
  <div class="landing-layout">
    <LandingStickyHeader
      :cta-btn-title="
        $t('b2b__landing_page__generic__cta_btn__claim_my_benefit')
      "
      :handle-c-t-a-button-click="handleCTAButtonClick"
      :show-sticky-header="showStickyHeader"
      :is-german="false"
    />

    <StartJourneyUS
      :cta-btn-title="$t('b2b__landing_page__generic__cta_btn__lets_go')"
      :handle-c-t-a-button-click="handleCTAButtonClick"
      :receive-c-t-a-button-client-rect="receiveCTAButtonClientRect"
      :is-german="false"
    />

    <ReclaimingLife
      :cta-btn-title="$t('b2b__landing_page__generic__cta_btn__title')"
      :handle-c-t-a-button-click="handleCTAButtonClick"
    />

    <RealMembers />

    <Landing3Steps
      :cta-btn-title="$t('b2b__landing_page__generic__cta_btn__short_title')"
      :handle-c-t-a-button-click="handleCTAButtonClick"
    />

    <FaqModule />

    <Contacts />

    <LandingPageFooter
      :static-company-address="$t('static_contacts_address_US')"
      :corporate="corporate"
      :lang="lang"
    />
  </div>
</template>

<script>
import { debounce } from '@shared/utils'
import { mapGetters } from 'vuex'
import ROUTE from '@msk-us/router/names'
import Contacts from '@shared/components/Contacts.vue'
import FaqModule from '@shared/components/FaqModule.vue'
import LandingPageFooter from '@shared/components/LandingPageFooter.vue'
import Landing3Steps from '@shared/components/Landing3Steps.vue'
import LandingStickyHeader from '@shared/components/LandingStickyHeader.vue'
import ReclaimingLife from '@shared/components/ReclaimingLife.vue'
import StartJourneyUS from '@msk-us/components/StartJourneyUS.vue'
import RealMembers from '@shared/components/RealMembers.vue'
import { useI18n } from 'vue-i18n'

export default {
  name: 'B2BLandingLayout',
  components: {
    Contacts,
    FaqModule,
    LandingPageFooter,
    Landing3Steps,
    LandingStickyHeader,
    ReclaimingLife,
    StartJourneyUS,
    RealMembers,
  },
  props: {
    setPageTitle: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data() {
    return {
      getCTAButtonClientRect: () => {},
      getInteractiveVideoClientRect: () => {},
      shouldStartInteractiveContent: false,
      debounceHandleScroll: null,
    }
  },
  computed: {
    ...mapGetters('b2b', ['corporate']),
    ...mapGetters(['lang']),
    pageTitle() {
      return this.t('b2b__landing_page__generic__page_title')
    },
  },
  mounted() {
    const handleScroll = () => {
      const rect = this.getInteractiveVideoClientRect()
      if (rect != null && rect.top <= rect.height / 4) {
        this.shouldStartInteractiveContent = true

        window.removeEventListener('scroll', this.debounceHandleScroll)
        this.debounceHandleScroll = null
      }
    }

    this.debounceHandleScroll = debounce(handleScroll, 100)
    window.addEventListener('scroll', this.debounceHandleScroll)
  },
  created() {
    this.updatePageTitle()
  },
  updated() {
    this.updatePageTitle()
  },
  beforeUnmount() {
    if (this.debounceHandleScroll != null) {
      window.removeEventListener('scroll', this.debounceHandleScroll)
      this.debounceHandleScroll = null
    }
  },
  methods: {
    handleCTAButtonClick() {
      if (this.corporate) {
        this.$router.push({
          name: ROUTE.B2B_SELECT_VERIFICATION_METHOD,
        })
      } else {
        this.$router.push({
          name: ROUTE.B2B_PARTNERS,
        })
      }
    },
    showStickyHeader() {
      const rect = this.getCTAButtonClientRect()
      return rect != null && rect.top <= -rect.height / 2
    },
    receiveCTAButtonClientRect(callback) {
      this.getCTAButtonClientRect = callback
    },
    updatePageTitle() {
      this.setPageTitle(
        [
          this.corporate?.title ? `${this.corporate.title} | ` : '',
          this.pageTitle,
        ].join(''),
      )
    },
    receiveInteractiveVideoClientRect(callback) {
      this.getInteractiveVideoClientRect = callback
    },
  },
}
</script>

<style lang="scss">
.landing-layout {
  width: 100%;
  min-width: 320px;
  margin: 0 auto;
}
</style>
