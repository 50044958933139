<template>
  <div class="simple-check-in">
    <div
      v-if="showingQuestions"
      ref="body"
      class="b-info b__transition b__transition--enter-from"
    >
      <h3 class="b-info__header">
        {{ $t('b2b__simple_landing_page__check_in__title') }}
      </h3>
      <BaseButton
        variant="outlined"
        :text="$t('b2b__simple_landing_page__check_in__option_not_often')"
        @click="handleChoice('not_often')"
      />

      <BaseButton
        variant="outlined"
        :text="$t('b2b__simple_landing_page__check_in__option_once_in_a_while')"
        @click="handleChoice('once_in_a_while')"
      />

      <BaseButton
        variant="outlined"
        :text="
          $t('b2b__simple_landing_page__check_in__option_a_few_times_a_week')
        "
        @click="handleChoice('a_few_times_a_week')"
      />

      <BaseButton
        variant="outlined"
        :text="$t('b2b__simple_landing_page__check_in__option_daily_battle')"
        @click="handleChoice('daily_battle')"
      />
    </div>

    <div
      v-else
      ref="header"
      class="b-info b__transition b__transition--enter-from"
    >
      <h3 class="b-info__header">
        {{ $t('b2b__simple_landing_page__check_in__loader') }}
      </h3>
      <DotsLoader />
    </div>
  </div>
</template>

<script>
import trackDetailedViewScreen from '@msk-us/helpers/trackDetailedViewScreen.js'

import BaseButton from '@shared/components/BaseButton.vue'
import DotsLoader from '@msk-us/components/DotsLoader.vue'
import Tracker from '@shared/Tracker'

export default {
  name: 'SimpleCheckIn',
  components: {
    DotsLoader,
    BaseButton,
  },
  props: {
    handleCTAButtonClick: {
      type: Function,
      default: () => {},
    },
    corporate: {
      type: Object,
      default: () => {},
    },
    utms: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showingQuestions: false,
      timerId: null,
    }
  },
  mounted() {
    this.timerId = setTimeout(() => {
      this.$refs.header.classList.remove('b__transition--enter-from')

      trackDetailedViewScreen(
        Tracker,
        'onb_checking_in',
        this.corporate,
        this.utms,
      )
    }, 100)

    this.timerId = setTimeout(() => {
      this.$refs.header.classList.add('b__transition--leave-to')

      this.showingQuestions = true
      this.timerId = setTimeout(() => {
        this.$refs.body.classList.remove('b__transition--enter-from')

        trackDetailedViewScreen(
          Tracker,
          'onb_pain_frequency',
          this.corporate,
          this.utms,
        )
      }, 100)
    }, 2000)
  },
  unmounted() {
    clearTimeout(this.timerId)
    this.timerId = null
  },
  methods: {
    handleChoice(value) {
      this.timerId = setTimeout(() => {
        this.$refs.body.classList.add('b__transition--leave-to')
        this.handleCTAButtonClick(value)
      }, 100)
    },
  },
}
</script>

<style lang="scss">
@import '@msk-us/components/css/SimpleCheckIn.scss';
</style>
