<template>
  <div class="partner-search-intro js-partner-search-intro">
    <h3
      class="partner-search-intro__header"
      v-html="getIntroHeader"
    />
    <div
      class="partner-search-intro__description"
      v-html="getIntroDescription"
    />
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'

export default {
  name: 'PartnerSearchIntro',
  props: {
    country: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  computed: {
    getIntroHeader() {
      return this.t('b2b__partner_search__intro__header')
    },
    getIntroDescription() {
      return this.t('b2b__partner_search__intro__description')
    },
  },
}
</script>

<style lang="scss">
@import '@msk-us/components/css/PartnerSearch/PartnerSearchIntro.scss';
</style>
