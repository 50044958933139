<template>
  <!-- eslint-disable vue/no-v-html -->
  <UnauthenticatedLayout class="kds-content-block">
    <div class="kds-content-block">
      <p
        class="kaia-p3-bold"
        v-html="$t('b2b_email_code_welcome_text', { name: corporateName })"
      />
      <div
        class="instructions"
        v-html="$t('b2b__virginpulse_signup_completed__description')"
      />
      <BaseBanner
        v-if="message"
        variant="warning"
      >
        <p v-html="$t(message)" />
      </BaseBanner>
      <TextField
        v-bind="fieldAttrs.firstName"
        :label="$t('b2b_sign_up_name_label')"
        autocomplete="given-name"
        data-qa="first-name"
      />

      <TextField
        v-bind="fieldAttrs.email"
        type="email"
        autocomplete="email"
        :label="$t('generic_field_email')"
      />

      <DateOfBirthField
        v-bind="fieldAttrs.dateOfBirth"
        :lang="lang"
      />

      <PhonenumberField
        v-bind="fieldAttrs.phoneNumber"
        :label="$t('b2b_sign_up_phone_number_label')"
        :country="country"
        data-qa="phone-number"
      />

      <BaseBanner variant="info">
        <CheckboxField
          v-bind="fieldAttrs.acceptTerms"
          data-qa="accept-terms-and-conditions"
        >
          <DocumentViewer :link="$t('b2b__new_account__accept_terms')" />
        </CheckboxField>
      </BaseBanner>

      <BaseButton
        data-qa="save"
        :loading="loading"
        :success="success"
        @click="submit"
      >
        {{ $t('generic_submit') }}
      </BaseButton>
    </div>
  </UnauthenticatedLayout>
  <!--eslint-enable-->
</template>

<script setup>
/** @typedef {import('vue').Ref} Ref */
import { ref, computed, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import { capitalizeFirstLetter } from '@shared/utils'
import useForm from '@shared/composables/useForm'

import {
  SIGN_UP_MALFORMED_ERROR,
  UNKNOWN_ERROR,
} from '@msk-us/config/common-errors'
import ROUTE from '@msk-us/router/names'

import BaseBanner from '@shared/components/BaseBanner.vue'
import BaseButton from '@shared/components/BaseButton.vue'

import TextField from '@shared/components/form/TextField.vue'
import DateOfBirthField from '@shared/components/form/DateOfBirthField.vue'
import PhonenumberField from '@shared/components/form/PhonenumberField.vue'
import CheckboxField from '@shared/components/form/CheckboxField.vue'
import DocumentViewer from '@shared/components/DocumentViewer.vue'

import UnauthenticatedLayout from '@msk-us/layouts/UnauthenticatedLayout.vue'
import { sendUserFeedback } from '@shared/api-endpoints/index.js'

const store = useStore()
const router = useRouter()

const formData = computed(() => store.getters['b2b/formData'])
const country = computed(() => store.getters.country)
const user = computed(() => store.getters.user)
const lang = computed(() => store.getters.lang)
const corporateName = computed(
  () => capitalizeFirstLetter(store.getters['b2b/corporate'].title) || '',
)

/** @type {Ref<string | null>} */
const message = ref(null)

/** @type {Ref<{ firstName: string, email: string, dateOfBirth: string, phoneNumber: string, acceptTerms: boolean }>} */
const form = ref({
  firstName: formData.value.firstName || user.value.profile.firstName,
  email: formData.value.email || user.value.email,
  dateOfBirth: formData.value.dateOfBirth || '',
  phoneNumber: formData.value.phoneNumber || user.value.profile.phoneNumber,
  acceptTerms: formData.value.acceptTerms || false,
})

watchEffect(() => {
  store.commit('b2b/setFormData', { ...form.value })
})

const formConfig = computed(() => ({
  firstName: { validations: ['required'] },
  email: { validations: ['required', 'email'] },
  dateOfBirth: { validations: ['dob'] },
  phoneNumber: { validations: ['required'] },
  acceptTerms: { validations: ['accepted'] },
}))

const { fieldAttrs, loading, submit, success } = useForm(form, formConfig, {
  onSubmit: async () => {
    message.value = null

    // Set Email
    if (form.value.email?.toLowerCase() !== user.value.email?.toLowerCase()) {
      await store.dispatch('setUserEmail', form.value.email)
    }

    // Set Profile
    await store.dispatch('setUserProfile', {
      firstName: form.value.firstName,
      phoneNumber: form.value.phoneNumber.split(' ').join(''),
      // Directly true for US users, because they don't have to accept the terms separately
      acceptedDataProtection: true,
      acceptedTracking: true,
      stayLoggedIn: true,
    })

    // Send Feedback
    await sendUserFeedback({
      dateOfBirth: form.value.dateOfBirth,
    })
  },
  onSuccess: () => {
    router.push({
      name: ROUTE.B2B_DOWNLOAD_LINK,
    })
  },
  onError: (error) => {
    message.value = null

    switch (error?.response?.status) {
      case 400:
        message.value = SIGN_UP_MALFORMED_ERROR
        break
      case 403:
        message.value = 'b2b_sign_up_exist_email_message'
        break
      default:
        message.value = UNKNOWN_ERROR
    }
  },
})
</script>
