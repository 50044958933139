<template>
  <div class="download-link">
    <HeaderCtrls
      :ctrls="headerCtrls"
      @action="handleHeaderCrtlsAction"
    />
    <HelpAndSupport
      v-if="showHelpAndSupport"
      :mail-to="mailTo"
    />
    <div
      v-if="showSpinner"
      class="download-link__spinner"
    >
      <Base64Spinner />
    </div>
    <div v-else>
      <h2
        class="download-link__header"
        v-html="headerText"
      />
      <div class="info-section info-section--success download-link__info">
        <div
          class="info-section__header"
          v-html="infoSectionHeader"
        />
        <div
          class="info-section__description"
          v-html="infoSectionDescription"
        />
      </div>
      <div
        v-if="state !== STATES.IN_APP"
        class="download-link__ctrls"
      >
        <div
          class="download-link__ctrls-header"
          v-html="ctrlsHeaderText"
        />

        <div
          class="download-link__ctrls-description"
          v-html="ctrlsDescriptionText"
        />
      </div>

      <div
        v-if="state === STATES.DESKTOP_BROWSER"
        class="download-link__qr-codes qr-codes"
      >
        <img
          class="qr-codes__item"
          :src="appleQRCodeUrl"
          alt="Kaia iOS QR Code"
        />
        <img
          class="qr-codes__item"
          :src="googleQRCodeUrl"
          alt="Kaia Android QR Code"
        />
      </div>

      <div
        v-if="state !== STATES.IN_APP"
        class="download-link__store"
      >
        <StoreBadge
          v-if="showAppStoreLink"
          class="download-link__store-item"
          :lang="lang"
          app-type="ios"
          @click="handleStoreLinkClick('App Store')"
        />
        <StoreBadge
          v-if="showPlayStoreLink"
          class="download-link__store-item"
          :lang="lang"
          app-type="android"
          @click="handleStoreLinkClick('Google Play')"
        />
      </div>
      <div
        v-if="state === STATES.IN_APP"
        class="download-link__redirect-cta"
      >
        <BaseButton
          :text="redirectDeepLinkText"
          @click="handleRedirectLinkClickEvent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { createHelpers } from '@shared/vuex-utils'
import { isMobile, isAndroidAgent, logW } from '@shared/utils'
import { isFromApp } from '@shared/helpers/isFromApp'
import { useI18n } from 'vue-i18n'

import env from '@shared/env.js'
import assembleKaiaAppDeepLink from '@shared/helpers/assembleKaiaAppDeepLink.js'
import getQRCodeUrl from '@shared/helpers/getQRCodeUrl.js'

import Base64Spinner from '@shared/components/Base64Spinner.vue'
import HeaderCtrls from '@shared/components/HeaderCtrls.vue'
import HelpAndSupport from '@shared/components/HelpAndSupport.vue'
import BaseButton from '@shared/components/BaseButton.vue'
import Tracker from '@shared/Tracker'
import StoreBadge from '@shared/components/StoreBadge.vue'

const { mapFields } = createHelpers({
  getterType: 'b2b/getField',
  mutationType: 'b2b/updateField',
})

export default {
  name: 'DownloadLink',
  components: {
    StoreBadge,
    Base64Spinner,
    HeaderCtrls,
    HelpAndSupport,
    BaseButton,
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data() {
    return {
      showHelpAndSupport: false,
      showSpinner: false,
      STATES: {
        IN_APP: 0,
        MOBILE_BROWSER: 1,
        DESKTOP_BROWSER: 2,
      },
    }
  },
  computed: {
    ...mapFields({
      redirectLink: 'redirectLink',
    }),
    ...mapGetters('b2b', ['corporate']),
    ...mapGetters(['user', 'lang']),
    state() {
      if (isFromApp()) {
        return this.STATES.IN_APP
      }
      if (isMobile()) {
        return this.STATES.MOBILE_BROWSER
      }
      return this.STATES.DESKTOP_BROWSER
    },
    appleQRCodeUrl() {
      return getQRCodeUrl(this.lang, 'ios')
    },
    googleQRCodeUrl() {
      return getQRCodeUrl(this.lang, 'android')
    },
    showPlayStoreLink() {
      return this.state === this.STATES.DESKTOP_BROWSER || isAndroidAgent()
    },
    showAppStoreLink() {
      return this.state === this.STATES.DESKTOP_BROWSER || !isAndroidAgent()
    },
    headerText() {
      if (env.VITE_GEO === 'us') {
        if (this.state === this.STATES.IN_APP) {
          return this.user == null
            ? this.t('us__download_page__header__deeplink__no_user')
            : this.t('us__download_page__header__deeplink__with_user')
        }

        return this.user == null
          ? this.t('us__download_page__header__no_user')
          : this.t('us__download_page__header__with_user')
      }

      if (this.state === this.STATES.IN_APP) {
        return this.user == null
          ? this.t('eu__download_page__header__deeplink__no_user')
          : this.t('eu__download_page__header__deeplink__with_user')
      }

      return this.user == null
        ? this.t('eu__download_page__header__no_user', {
            app_name: this.t('eu__generic__app'),
          })
        : this.t('eu__download_page__header__with_user')
    },
    ctrlsHeaderText() {
      if (env.VITE_GEO === 'us') {
        return this.t('download_page__ctrls_header', {
          app_name: this.t('eu__generic__app'),
        })
      }

      return this.t('eu__download_page__ctrls_header', {
        app_name: this.t('eu__generic__app'),
      })
    },
    ctrlsDescriptionText() {
      if (env.VITE_GEO === 'us') {
        return this.t('download_page__ctrls_description')
      }

      return this.t('eu__download_page__ctrls_description', {
        app_name: this.t('eu__generic__app'),
      })
    },
    mailTo() {
      return `mailto:support@kaiahealth.de?subject=${encodeURIComponent(
        this.t('eu__generic__app'),
      )}`
    },
    selectedInsuranceType() {
      const userProfileInsuranceType = this.user?.profile?.insuranceType || ''
      if (userProfileInsuranceType === 'private_insurance') {
        return 'private'
      }
      if (userProfileInsuranceType === 'public_insurance') {
        return 'public'
      }
      return 'not_set'
    },
    headerCtrls() {
      if (this.state === this.STATES.IN_APP || env.VITE_GEO === 'us') {
        // empty header
        return {}
      }
      return this.showHelpAndSupport
        ? { lang: 1, close: 1 }
        : { lang: 1, support: 1 }
    },
    infoSectionHeader() {
      if (env.VITE_GEO === 'us') {
        if (this.state === this.STATES.IN_APP) {
          return this.user == null
            ? this.t('us__download_page__info_header__deeplink__no_user')
            : this.t('us__download_page__info_header__deeplink__with_user')
        }

        return this.user == null
          ? this.t('us__download_page__info_header__no_user')
          : this.t('us__download_page__info_header__with_user')
      }

      if (this.state === this.STATES.IN_APP) {
        return this.user == null
          ? this.t('eu__download_page__info_header__deeplink__no_user')
          : this.t('eu__download_page__info_header__deeplink__with_user')
      }

      return this.user == null
        ? this.t('eu__download_page__info_header__no_user')
        : this.t('eu__download_page__info_header__with_user')
    },
    infoSectionDescription() {
      if (env.VITE_GEO === 'us') {
        if (this.state === this.STATES.IN_APP) {
          return this.user == null
            ? this.t('us__download_page__info_description__deeplink__no_user', {
                support_email: env.VITE_SUPPORT_EMAIL,
              })
            : this.t('us__download_page__info_description__deeplink__with_user')
        }

        return this.user == null
          ? this.t('us__download_page__info_description__no_user', {
              support_email: env.VITE_SUPPORT_EMAIL,
            })
          : this.t('us__download_page__info_description__with_user', {
              user_email: this.user.email,
            })
      }

      if (this.state === this.STATES.IN_APP) {
        return this.user == null
          ? this.t('eu__download_page__info_description__deeplink__no_user', {
              support_email: env.VITE_SUPPORT_EMAIL,
            })
          : this.t('eu__download_page__info_description__deeplink__with_user')
      }

      if (this.user == null) {
        return this.t('eu__download_page__info_description__no_user', {
          support_email: env.VITE_SUPPORT_EMAIL,
        })
      } else {
        if (this.corporate.isPublicInsurance) {
          return this.t(
            'eu__download_page__info_description__with_user_public',
            {
              app_name: this.t('eu__generic__app'),
            },
          )
        } else {
          return this.t('eu__download_page__info_description__with_user', {
            app_name: this.t('eu__generic__app'),
          })
        }
      }
    },
    redirectDeepLinkText() {
      return this.user == null
        ? this.t('generic_action_continue')
        : this.t('b2b_get_started_btn_text')
    },
  },
  async mounted() {
    if (this.state === this.STATES.IN_APP) {
      this.showSpinner = true
      await this.ensureDeepLink()
      this.automaticDeepLinkRedirect()
    }
  },
  methods: {
    async getDeepLink() {
      if (this.user) {
        try {
          return assembleKaiaAppDeepLink('auth/one_time_token', {}, true)
        } catch (e) {
          logW(
            'failed to append oneTimeToken to deeplink, using fallback deeplink',
            e,
          )
          return assembleKaiaAppDeepLink('open')
        }
      }
      if (this.corporate) {
        return assembleKaiaAppDeepLink('signup/webcheckout', {
          corporate: this.corporate.key,
          from_app: true,
          skip_information: true,
        })
      }
      return assembleKaiaAppDeepLink('goto/screen', { target: 'login' })
    },
    async ensureDeepLink() {
      // dont reload the deeplink if it already exists and was created less than 30 seconds ago
      if (
        this.redirectLink &&
        this.redirectLink.creationDate > Date.now() - 1000 * 30
      ) {
        return
      }

      const link = await this.getDeepLink()
      this.redirectLink = {
        link,
        creationDate: Date.now(),
      }
    },
    automaticDeepLinkRedirect() {
      setTimeout(() => {
        this.showSpinner = false
      }, 1000)

      window.location.assign(this.redirectLink.link)
    },
    handleStoreLinkClick(storeName) {
      const custom_payload = {
        label: `Go to ${storeName}`,
        corporate_id: this.corporate.id,
        corporate_name: this.corporate.title,
      }

      if (this.selectedInsuranceType === 'public') {
        custom_payload.public_flow = true
      } else if (this.selectedInsuranceType === 'private') {
        custom_payload.private_flow = true
      }

      Tracker.trackKaiaEvent(
        {
          event_name: 'c.lfcycl.select_button',
          app_area: 'lifecycle',
          action: 'view',
          object_type: 'screen',
          source: 'web-checkout',
          screen_name: 'signup_app_download',
        },
        {
          custom_payload,
        },
      )
    },
    handleHeaderCrtlsAction(type) {
      if (type === 'support') {
        document.documentElement.scrollTop = 0
        document.body.classList.add('noscrollY')
        this.showHelpAndSupport = true
      } else if (type === 'close') {
        this.showHelpAndSupport = false
        document.body.classList.remove('noscrollY')
      }
    },
    handleRedirectLinkClickEvent() {
      window.location.assign(this.redirectLink.link)
    },
  },
}
</script>

<style lang="scss">
@import '@shared/components/css/InfoSection.scss';
@import '@shared/views/css/DownloadLink.scss';
</style>
