import store from '@msk-us/state/store'
import ROUTE from '@msk-us/router/names'
import VueCookieConsent from '@shared/VueCookieConsent'
import { isFromApp } from '@shared/helpers/isFromApp'
import preFillVouchers from '@msk-us/config/b2b-corporate-pre-fill-vouchers'
import downmarketLinks from '@msk-us/config/b2b-downmarket-links'
import startkaiaDeLinks from '@msk-us/config/b2b-startkaia-de-links'
import { decodeQuery, getCookie } from '@shared/utils.js'
import { saveAuthQueryToStore } from '@shared/api-client'

export const B2B_AUTH_METHODS = {
  VOUCHER: 'voucher',
  EMAIL: 'email',
  ELIGIBILITY_LIST: 'eligibility_list',
  PARTICIPATION_ID: 'participation_id',
  SOLERA: 'solera',
  VIRGINPULSE_SSO: 'virginpulse_sso',
  UHCHUB: 'uhchub',
}

const preferredSignUpMethodMap = {
  [B2B_AUTH_METHODS.VOUCHER]: ROUTE.B2B_VOUCHER,
  [B2B_AUTH_METHODS.EMAIL]: ROUTE.B2B_EMAIL,
  [B2B_AUTH_METHODS.UHCHUB]: ROUTE.B2B_UHCHUB_VERIFICATION,
  [B2B_AUTH_METHODS.ELIGIBILITY_LIST]:
    ROUTE.B2B_CORPORATE_ELIGIBILITY_LIST_VERIFICATION,
  [B2B_AUTH_METHODS.PARTICIPATION_ID]: ROUTE.B2B_PARTICIPATION_ID_VERIFICATION,
  [B2B_AUTH_METHODS.SOLERA]: ROUTE.B2B_SOLERA_VERIFICATION,
  [B2B_AUTH_METHODS.VIRGINPULSE_SSO]: ROUTE.B2B_VIRGINPULSE_SSO_INITIATED,
}

const getVerificationParams = ($route, $store) => (param) =>
  ($store.getters['b2b/verificationParams'] &&
    $store.getters['b2b/verificationParams'][param]) ||
  $route.query[param]

const isVerificationDataAvailable = (
  corporate,
  voucher,
  corporateEligibilityData,
) => {
  switch (corporate.preferredSignupMethod) {
    case B2B_AUTH_METHODS.VOUCHER:
      return voucher && voucher.code
    case B2B_AUTH_METHODS.EMAIL:
      return voucher && voucher.emailCode
    case B2B_AUTH_METHODS.ELIGIBILITY_LIST:
    case B2B_AUTH_METHODS.PARTICIPATION_ID:
    case B2B_AUTH_METHODS.SOLERA:
      return Object.keys(corporateEligibilityData || {}).length > 0
    default:
      return true
  }
}

const getCorporateKeyFromRoute = (route) =>
  (route.params.corp_name || route.query.corporate || '').toLowerCase()

const defaultSignUpMethod = B2B_AUTH_METHODS.VOUCHER

export const beforeEachB2B = async (routeTo, routeFrom, next) => {
  const redirectTo = (name, qs, params) =>
    next({ name, query: qs || routeTo.query, params: params || routeTo.params })
  let corporateName = getCorporateKeyFromRoute(routeTo)

  if (startkaiaDeLinks.includes(corporateName)) {
    window.location.replace(`https://startkaia.de/bp/${corporateName}`)
    return
  }

  switch (corporateName) {
    case 'vpt_social':
      return redirectTo(ROUTE.UHC_VPT_INSURANCE_SELECTION, {})
    case 'uhc_vpt_tile':
      return redirectTo(ROUTE.UHC_VPT_PRIMER, {})
  }

  if (routeTo.name === ROUTE.B2B_PARTNERS) {
    return next()
  }
  if (routeTo.name === ROUTE.B2B) {
    if (!corporateName) {
      return redirectTo(ROUTE.B2B_PARTNERS)
    }

    await store.dispatch(
      'b2b/storeVerificationParams',
      decodeQuery(routeTo.query),
    )

    if (downmarketLinks.includes(corporateName)) {
      return redirectTo(ROUTE.B2B_INFORMATION_GENERIC)
    }

    return redirectTo(
      ROUTE.B2B_INFORMATION,
      {},
      {
        corp_name: corporateName,
      },
    )
  }

  if ([ROUTE.B2B_INFORMATION_GENERIC].includes(routeTo.name)) {
    return next()
  }

  let corporate = null
  try {
    corporate = await store.dispatch('b2b/ensureCorporate', {
      name: corporateName,
    })
  } catch (e) {
    console.error(e)

    return redirectTo(
      ROUTE.B2B_PARTNERS,
      {},
      {
        corp_name: corporateName,
      },
    )
  }

  if (!corporate.isCustomer) {
    // TODO pass corporate key to partner search and show not customer warning there
    return redirectTo(
      ROUTE.B2B_PARTNERS,
      {},
      {
        corp_name: corporate.key,
      },
    )
  }

  VueCookieConsent(
    window,
    document,
    getCookie('CookieConsent'),
    store.getters.country,
    isFromApp(),
    store.getters.lang,
  )

  const verificationParams = getVerificationParams(routeTo, store)

  switch (routeTo.name) {
    case ROUTE.B2B_DOWNLOAD_LINK:
      if (store.getters.user == null) {
        return redirectTo(ROUTE.B2B_LOGIN)
      }
      return next()

    case ROUTE.B2B_INFORMATION:
      // skip the information screen if the corporate has disabled extended onboarding
      if (!corporate.showExtendedOnboarding) {
        return redirectTo(ROUTE.B2B_SELECT_VERIFICATION_METHOD)
      }

      // skip the information screen if its a login flow
      if (
        verificationParams('login') === 'true' ||
        verificationParams('one_time_token') ||
        verificationParams('token') ||
        verificationParams('skip_information')
      ) {
        return redirectTo(
          ROUTE.B2B_SELECT_VERIFICATION_METHOD,
          {},
          {
            corp_name: corporateName,
          },
        )
      }
      break

    case ROUTE.B2B_SELECT_VERIFICATION_METHOD: {
      let voucherCode =
        verificationParams('voucher_code') || verificationParams('voucher')
      const emailCode = verificationParams('email_code')
      const email = verificationParams('email')

      let signUpMethod = corporate.preferredSignupMethod
      if (!Object.values(B2B_AUTH_METHODS).includes(signUpMethod)) {
        signUpMethod = defaultSignUpMethod
      }

      const preFillVoucher = preFillVouchers[corporate.key]
      if (!voucherCode && preFillVoucher && verificationParams('startkaia')) {
        signUpMethod = B2B_AUTH_METHODS.VOUCHER
        voucherCode = preFillVoucher
      }

      return next({
        ...routeTo,
        name: preferredSignUpMethodMap[signUpMethod],
        params: {
          corp_name: corporate.key,
          ...(signUpMethod === B2B_AUTH_METHODS.VOUCHER &&
            voucherCode && { voucherCode }),
          ...(signUpMethod === B2B_AUTH_METHODS.EMAIL &&
            emailCode && { emailCode }),
        },
        query: {
          ...routeTo.query,
          ...(email && { email }),
        },
      })
    }

    case ROUTE.B2B_CORPORATE_ELIGIBILITY_VERIFICATION:
    case ROUTE.B2B_CORPORATE_ELIGIBILITY_LIST_VERIFICATION:
    case ROUTE.B2B_EMAIL:
      if (verificationParams('login') === 'true') {
        return redirectTo(
          ROUTE.B2B_VERIFY_NOT_POSSIBLE_FOR_EXISTING_USER,
          {},
          {
            corp_name: corporate.key,
          },
        )
      }
      break

    case ROUTE.B2B_VIRGINPULSE_SSO_INITIATED:
      if (store.getters.user) {
        return redirectTo(ROUTE.B2B_VIRGINPULSE_SSO_COMPLETED)
      }
      break

    case ROUTE.B2B_VIRGINPULSE_SSO_COMPLETED:
      saveAuthQueryToStore(routeTo.query)

      try {
        await store.dispatch('getUser')
      } catch (error) {
        let query = {}
        // TODO what other errors can happen that should not be handled with the default message?
        if (error.response?.status === 401) {
          query = { code: 'UNAUTHORIZED' }
        }
        return redirectTo(ROUTE.B2B_VIRGINPULSE_SSO_ERROR, query)
      }

      // Block the user from going any further without a valid subscription.
      // This should be removed as soon as subscriptions are handled correctly in the apps.
      if (store.getters.user && !store.getters.isPro) {
        return redirectTo(ROUTE.B2B_VIRGINPULSE_SSO_ERROR, {
          code: 'NO_ACTIVE_SUBSCRIPTION',
        })
      }

      if (store.getters.user?.profile?.hasAcceptedDataProtection) {
        return redirectTo(ROUTE.B2B_DOWNLOAD_LINK)
      }
      break

    case ROUTE.B2B_SIGN_UP: {
      const voucher = store.getters['b2b/voucher']
      const corporateEligibilityData = store.getters['b2b/corporateEligibility']

      if (
        verificationParams('login') === 'true' ||
        verificationParams('one_time_token')
      ) {
        return redirectTo(ROUTE.B2B_LOGIN)
      }

      if (
        !isVerificationDataAvailable(
          corporate,
          voucher,
          corporateEligibilityData,
        )
      ) {
        return redirectTo(ROUTE.B2B_SELECT_VERIFICATION_METHOD)
      }
      break
    }

    default:
      break
  }

  return next()
}
